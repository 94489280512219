//import customSelect from 'custom-select';

document.addEventListener('DOMContentLoaded',function() {

    const cover = document.getElementById('cover');
    
    const init = function() {
        //console.clear();
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        
        setTimeout(function() {
            cover.remove();
        }, 500);

        
        // Carousels
        document.getElementsByClassName('js-headerSlider')[0] ? window.headerSlider() : false;
        document.getElementsByClassName('js-gallerySlider')[0] ? window.gallerySlider() : false;

        // Sticky elements
        window.sticky();
        
        // Anims
        window.anims();
        
        document.getElementsByClassName('o-overlay')[0].style.pointerEvents = "none";
        

        
        
        // Grid Macy
/*
        
        document.getElementsByClassName('js-macy')[0] ? window.macy() : false;
        
        setTimeout(function() {
            
        }, )
*/
        
        
        // Select
    
/*
        (function() {
            
            const select = document.getElementsByTagName('select');
        
            const init = function() {
        
                const cstSel = customSelect(select);
                
                for (let i = 0; i < select.length; i ++) {
                    cstSel[i].container.addEventListener('custom-select:open', (e) => { 
                        e.target.style.position = 'relative';
                        e.target.style.zIndex = '5';
                    });
                    
                    cstSel[i].container.addEventListener('custom-select:close', (e) => { 
                        e.target.removeAttribute('style');
                    });
                }
            };
                    
            select ? init() : false;
            
        })();
*/

        // Anims on inview
        //window.animsInit()   
        
    };
    
    
    window.addEventListener('load', init);

}, false);