document.addEventListener('DOMContentLoaded', function() {

        let scroll_pos = window.pageYOffset || window.scrollY,
            out = false;
        
        const el = document.getElementsByClassName('js-repertoire')[0],
              topbar = document.getElementsByClassName('js-top')[0];
        
        let lastScrollTop = 0,
        	floating = false,
        	filters = document.getElementById('filters'),
        	filters_visible = false,
        	status = false;
        
        const action = function() {
	        
            scroll_pos = window.pageYOffset || window.scrollY;

			// floating top
            if (scroll_pos >= topbar.clientHeight + document.querySelector('.c-breadcrumbs').clientHeight) {
	            if (floating === false) {

		            el.classList.add('is-floating');
		            hideFilters();
		            
		            let dst = document.getElementsByClassName('js-floating')[0];
                	    
                    if (status === false) {
    					cutme.Helpers.detach(filters, dst);
    		        	status = true;
    		        }
		            
		            setTimeout(function() {
    		            el.classList.add('is-visible');
    		            floating = true;
		            }, 10);
	            }
            }
            
            else if (scroll_pos < topbar.clientHeight + document.querySelector('.c-breadcrumbs').clientHeight) {
                 if (floating === true) {
                    
                    el.classList.remove('is-visible');
                    hideFilters();
                     
                    let dst = document.getElementsByClassName('js-relative')[0];
                         
                    if (status === true) {
        				cutme.Helpers.detach(filters, dst);
        		        status = false;
        		    }
    
                    setTimeout(function() {
    		            el.classList.remove('is-floating');
        	            floating = false;
    	            }, 200);
    	        }
            }
        };
        
        const hideFilters = function(e) {
            filters.classList.remove('is-visible');
            
            setTimeout(function() {
	            filters.classList.remove('is-block');
	            filters_visible = false;
            }, 200);
        };
        
        const showFilters = function(e) {
            
            if (filters_visible === false) { 
                filters.classList.add('is-block');
                
                setTimeout(function() {
    	            filters.classList.add('is-visible');
    	            filters_visible = true;
                }, 200);
            } else {
                hideFilters();
            }

        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        };
        
        const filtering = function() {
        
            const tag = document.getElementsByClassName('js-tag'),
                  filterItem = document.getElementsByClassName('js-filterItem'),
                  grid = document.getElementById('grid'),
                  itemsPerRow = 3;
    
            let grid_default__arr = [], tagsArray = [], new_grid;
            
            
            const setDefaultGrid = function() {
                for (let i = 0; i < filterItem.length; i ++) {
                    grid_default__arr.push(filterItem[i]);            
                }
            }
            
            const compareTagsWithItems = function() {
                
                if (tagsArray.length > 0) {
    
                    for (let i = 0; i < filterItem.length; i++) {
                        filterItem[i].classList.add('is-hidden');
                    }
                    
                    for (let i = 0; i < filterItem.length; i++) {
                        filterItem[i].classList.add('is-hidden');
                        
                        for (let j = 0; j < tagsArray.length; j++) {                        
                            if (filterItem[i].classList.contains(tagsArray[j])) {
                                filterItem[i].classList.remove('is-hidden');
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < filterItem.length; i++) {
                        filterItem[i].classList.remove('is-hidden');
                    }
                }
            };
    
            const getActiveTags = function() {
                
                tagsArray = [];
                
                for (let i = 0; i < tag.length; i++) {
                
                if (tag[i].checked === true) {
                        tagsArray.push(tag[i].value);
                    }                
                }                
            };    

            // Click on tag Action        
            const action = function(e) {
                getActiveTags();
                compareTagsWithItems();
            };
            
            for (let i = 0; i < tag.length; i ++) {
                tag[i].onchange = action;
            }            
        };
        
        const filtersTrigger = function() {
            const filters__show = document.getElementsByClassName('js-filter'),
                  filters__close = filters.getElementsByClassName('js-close')[0];
            
            filters__close.addEventListener('click', hideFilters);            
            
            for (let i = 0; i < filters__show.length; i++) {
                filters__show[i].addEventListener('click', showFilters);
            }
            
            filtering();
        };

        
        el ? filtersTrigger() : false;
	    el ? window.addEventListener('scroll', action) : false;
        
        
}, false);