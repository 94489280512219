document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementsByClassName('js-contrast')[0];

    const init = function() {

        const action = function(e) {
            
            if (document.body.classList.contains('high-contrast')) {
                document.body.classList.remove('high-contrast');
                el.classList.remove('is-active');
            } else {
                document.body.classList.add('high-contrast');
                el.classList.add('is-active');
            }
                       
            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        };

        el.addEventListener('click', action);
    };


	el ? init() : false;


}, false);
