import { gsap } from "gsap";

import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded',function() {

    gsap.registerPlugin(ScrollTrigger);

    window.anims = function() {

        gsap.utils.toArray(".js-fadeIn").forEach(function(section) {

            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                    end: 'top center',
                    scrub: 1,
                },
                opacity: 0, 
                duration: 1,
                y: 50
            });
        });        
         
         
         // Fades in the targets given 
         
         gsap.utils.toArray(".js-stage").forEach(function(section) {
     
            gsap.from(section.querySelectorAll('.js-stage__item'), {
                scrollTrigger: {
                    trigger: section,
                    scrub: 1,
                    start: 'top bottom',
                    end: 'top center'
                },
                stagger: {
                    each: 0.1,
                },
                opacity: 0,
                y: 60,
            });
        });  
        
        
        
        
        
    };



}, false)
