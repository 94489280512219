import css from './sass/style.scss';

require('./js/artists.js');
require('./js/carousels.js');
require('./js/contrast.js');
require('./js/cookies.js');
require('./js/detach.js');
require('./js/fixel.js');
require('./js/gsap.js');
require('./js/helpers.js');
require('./js/macy.js');
require('./js/polyfills.js');
require('./js/init.js');
require('./js/ismobile.js');
require('./js/nav.js');
require('./js/repertoire.js');
require('./js/textsize.js');
