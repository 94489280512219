import stickybits from 'stickybits';

document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementsByClassName('js-sticky');

    window.sticky = function() {
        
        let offset = 0;
        let hasStickybit = true;

        // News 
        let stickynewsbit;
        const initNews = function() {

            if (window.innerWidth < 576 && hasStickybit) {                
                document.getElementsByClassName('js-news')[0].removeAttribute('style');
                //hasStickybit = false;
                //return stickynewsbit.cleanup();
                
            } else {
                
                offset = document.getElementsByClassName('js-news')[0].dataset.offset;
                return stickynewsbit = stickybits(document.getElementsByClassName('js-news')[0], {
                    stickyBitStickyOffset: offset,
                    useStickyClasses: true
                });
                
                hasStickybit = true;
            }
        };
        
        const initSticky = function() {

            if (window.innerWidth < 576 && hasStickybit) {                
                document.getElementsByClassName('js-sticky')[0].removeAttribute('style');
                //hasStickybit = false;
                //return stickynewsbit.cleanup();
                
            } else {
                
                offset = document.getElementsByClassName('js-top')[0].clientHeight;
                
                return stickynewsbit = stickybits(document.getElementsByClassName('js-sticky')[0], {
                    stickyBitStickyOffset: offset,
                    useStickyClasses: true
                });
                
                hasStickybit = true;
            }
        };
        
        
        
        if (document.getElementsByClassName('js-news').length > 0) {
            initNews()
            window.addEventListener('resize', cutme.Helpers.debounce(() => initNews(), 100, false));
        }
        
        if (document.getElementsByClassName('js-sticky').length > 0) {
            initSticky()
            window.addEventListener('resize', cutme.Helpers.debounce(() => initSticky(), 100, false));
        }

        
        // Top
        offset = document.getElementsByClassName('js-top')[0].dataset.offset;
        let stickytopbit = stickybits(document.getElementsByClassName('js-top')[0], {
            stickyBitStickyOffset: offset
        });
    };

    

}, false);

