document.addEventListener('DOMContentLoaded',function() {

    const premiereBuy = document.getElementsByClassName('js-premiere-buy')[0],
          news = document.getElementsByClassName('js-news-detach')[0];

    let smartphone_portrait = 576;


    // Home News Section 2    
    const newsInit = function() {
        
        function unwrap(wrapper) {
            // place childNodes in document fragment
            var docFrag = document.createDocumentFragment();
            while (wrapper.firstChild) {
                var child = wrapper.removeChild(wrapper.firstChild);
                docFrag.appendChild(child);
            }
        
            // replace wrapper with document fragment
            wrapper.parentNode.replaceChild(docFrag, wrapper);
        }

    	let status = false,
    	    src = document.getElementsByClassName('js-news-src')[0],
    	    dst = document.getElementsByClassName('js-news-dst')[0];

    	const action = function(e) {
	    	
	    	let ww = window.innerWidth;

	    	if (ww <= smartphone_portrait) {
		    	if (status === false) {

    		    	cutme.Helpers.detach(document.getElementsByClassName('js-news-detach')[0], dst);
    		    	unwrap(document.querySelector('.js-news-detach'));   
    		    	
    		    	for(let i = 0; i < dst.getElementsByClassName('js-news-item').length; i++) {
                        dst.getElementsByClassName('js-news-item')[i].classList.remove('c-news__item');
                        dst.getElementsByClassName('js-news-item')[i].classList.add('o-cols__item');
                    }
    		    	 		    	
		        	status = true;
		        }
	        	
	        } else {
		        if (status === true) {
    		        
    		        let div = document.createElement("div");
                    div.className ="o-cols__item js-news-detach";
                    src.appendChild(div);
                    
                    for(let i = 0; i < document.getElementsByClassName('js-news-item').length; i++) {
                        document.getElementsByClassName('js-news-detach')[0].appendChild(document.getElementsByClassName('js-news-item')[i])
                    }
                    
                    for(let i = 0; i < src.getElementsByClassName('js-news-item').length; i++) {
                        src.getElementsByClassName('js-news-item')[i].classList.add('c-news__item');
                        src.getElementsByClassName('js-news-item')[i].classList.remove('o-cols__item');
                    }
    		        
					cutme.Helpers.detach(news, src);
		        	status = false;
			    }
	        }
	    };

        action();

        window.addEventListener('resize', cutme.Helpers.debounce(() => action(), 100, false));
    };

    // Premiere
	const premiereBuyInit = function() {    	

    	let status = false,
    	    src = document.getElementsByClassName('js-premiere-src')[0],
    	    dst = document.getElementsByClassName('js-premiere-dst')[0];

    	const action = function(e) {
	    	
	    	let ww = window.innerWidth;

	    	if (ww <= smartphone_portrait) {
		    	
		    	if (status === false) {
					cutme.Helpers.detach(premiereBuy, dst);
		        	status = true;
		        }
	        	
	        } else {
		        if (status === true) {
					cutme.Helpers.detach(premiereBuy, src);
		        	status = false;
			    }
	        }
	    };
	    
        action();
        
        window.addEventListener('resize', cutme.Helpers.debounce(() => action(), 100, false));
    };
	
	news ? newsInit() : false;
	premiereBuy ? premiereBuyInit() : false;
    
    

}, false);
