import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

Swiper.use([Navigation, Pagination, Autoplay]);
gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded',function() {
    
    const galleryEl = document.getElementsByClassName('js-gallerySlider')[0];
    
    window.headerSlider = function() {
        
        const showLeads = function() {
            gsap.from(['.swiper-slide-active .js-headerLead__big', '.swiper-slide-active .js-headerLead__small'], {
                opacity: 0, 
                scale: 2
            })
            gsap.to('.swiper-slide-active .js-headerLead__big', {
                opacity: 1, 
                duration: .5, 
                scale: 1
            })

            gsap.to('.swiper-slide-active .js-headerLead__small', {
                opacity: 1, 
                duration: .5, 
                scale: 1,
                delay: .2
            })
        };
        
        const stopWhenHidden = function() {
            let stopped = false;

            const action = function() {
                let scroll_pos = window.pageYOffset || window.scrollY;
                
                if (scroll_pos >= window.innerHeight) {
                    if (stopped === false) {
                        swiper.autoplay.stop();
                        stopped = true;
                    }
                } else {
                    if (stopped === true) {
                        swiper.autoplay.start();
                        stopped = false;
                    }
                }
            };

            window.addEventListener('scroll', action);
        }
        
        const test = function() {
            window.swiper.update();
        }
        
	    window.swiper = new Swiper(document.getElementsByClassName('js-headerSlider')[0], {
    	    autoplay: {
        	    delay: 5000
    	    },
            speed: 600,
            loop: true,
            on: {
                init: function () {
                    showLeads();
                    stopWhenHidden();
                    
                    window.addEventListener('resize', cutme.Helpers.debounce(() => test(), 500, false));
                },
                
                transitionEnd: function() {
                    showLeads();
                    
                    gsap.to(['.swiper-slide-prev .js-headerLead__big', '.swiper-slide-prev .js-headerLead__small'], {
                        opacity: 0, 
                        scale: 2
                    })
                },
            },
            pagination: {
                el: '.swiper-pagination',
                    clickable: true,
                },
            });
            
    };
    
    ScrollTrigger.create({
        trigger: '.js-header',
        start: "top top", 
        scrub: true,
        pin: true, 
        pinSpacing: false
    });
    
    
    
    window.gallerySlider = function() {
                        
        const swiper = new Swiper(galleryEl, {
            allowTouchMove: true,
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 500,
            pagination: {
                el: '.js-gallerySlider .swiper-pagination',
                    clickable: true,
            },
            navigation: {
                nextEl: '.js-gallerySlider .swiper-button-next',
                prevEl: '.js-gallerySlider .swiper-button-prev',
            },
        });
    };
    
    
    

}, false)
