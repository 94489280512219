document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementsByClassName('js-textsize');

    const init = function() {

        const action = function(e) {

            const size = e.currentTarget.dataset.size;

            switch(size) {
                case 'normal': document.documentElement.style.fontSize = '100%'; break;
                case 'medium': document.documentElement.style.fontSize = '150%'; break;
                case 'big': document.documentElement.style.fontSize = '200%'; break;                
            }

            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        };

        for (let i = 0; i < el.length; i++) {
            el[i].addEventListener('click', action);
        }
    };

	el.length > 0 ? init() : false;

}, false);
