import Macy from 'macy';

document.addEventListener('DOMContentLoaded',function() {
            
    const el = document.getElementsByClassName('js-macy')[0],
          gallery2 = document.getElementsByClassName('js-gallery--2')[0],
          gallery3 = document.getElementsByClassName('js-gallery--3')[0];
    
    const init = function() {
        const macy = Macy({
            container: el,
            trueOrder: false,
            waitForImages: true,
            margin: 40,
            columns: 3,
            breakAt: {
                1024: {
                    margin: 30
                },
                640: {
                    columns: 2,
                },
                540: {
                    margin: 20
                },
            }
        });
    	
    	setTimeout(function() {
        	macy.recalculate(true, true);
    	}, 500);
    };
    
    const gallery2Init = function() {
        const macy = Macy({
            container: gallery2,
            trueOrder: false,
            waitForImages: true,
            margin: 40,
            columns: 2,
            breakAt: {
                1024: {
                    margin: 30
                },
                640: {
                    columns: 2,
                },
                540: {
                    margin: 20
                },
            }
        });
    	
    	setTimeout(function() {
        	macy.recalculate(true, true);
    	}, 500);
    };
    
    const gallery3Init = function() {
        const macy = Macy({
            container: gallery3,
            trueOrder: false,
            waitForImages: true,
            margin: 40,
            columns: 3,
            breakAt: {
                1024: {
                    margin: 30
                },
                640: {
                    columns: 2,
                },
                540: {
                    margin: 20
                },
            }
        });
    	
    	setTimeout(function() {
        	macy.recalculate(true, true);
    	}, 500);
    };
    
    el ? init() : false;
    gallery2 ? gallery2Init() : false;
    gallery3 ? gallery3Init() : false;

}, false);
