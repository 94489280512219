document.addEventListener('DOMContentLoaded',function() {
    
   const el = document.getElementsByClassName('js-artists')[0];
   
   const init = function() {
       
       const more = document.getElementsByClassName('js-more'),
             full = document.getElementsByClassName('js-full');
       
       const action = function(e) {
           
            const _this = e.currentTarget;           

            if (_this.classList.contains('is-active')) {
                _this.classList.remove('is-active');
                el.classList.remove('is-faded');

            } else {
                
                for (let j = 0; j < more.length; j++) {
                    more[j].classList.remove('is-active');
                }
                
                _this.classList.add('is-active');
                el.classList.add('is-faded');
            }
           
       };
       
       for (let i = 0; i < more.length; i ++) {
           more[i].addEventListener('click', action);
       }
       
   };
   
   el ? init() : false;

}, false)
